.alert {
  --link-color: #{$light};

  position: fixed;
  inset: 0 0 auto 0;
  z-index: z-index(header) + $z-index-step;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: rem(10) var(--container-gutter);

  font-weight: 400;
  font-size: rem(12);
  line-height: rem(16);
  color: $tertiary;

  min-height: var(--alert-height, rem(40));

  background: $dark;
  opacity: 0;
  transform: translateY(-100%);
  transition: $transition-default;

  &.--is-show {
    transform: none;
    opacity: 1;
  }

  &__wrap {
    display: grid;
    grid-auto-flow: column;
    grid-gap: rem(32);
    align-items: center;
  }

  &__warn {
    display: inline-flex;
    align-items: center;
    gap: rem(4);
    //grid-area: var(--alert-grid-area-1, none);

    color: $secondary;
    font-weight: bold;
  }

  &__close {
    position: absolute;
    top: 50%;
    right: rem(20);

    font-size: rem(14);

    transform: translateY(-50%);

    @include only-mouse {
      &:hover {
        color: $secondary;
      }
    }
  }

  @include max-wide(phonesLg) {
    justify-content: initial;
    --alert-grid-area-1: 1/1;

    &__wrap {
      grid-gap: rem(20);

      width: 100%;

      margin-right: rem(24);
    }

    &__link {
      grid-area: 1/2;
      text-align: right;
    }

    &__item {
      &:nth-of-type(2) {
        grid-area: 2/1;
      }

      &:nth-of-type(1) {
        grid-area: 2/2;
        //justify-self: flex-end;
      }
    }
  }
}
