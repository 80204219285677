.aside {
  display: grid;
  grid-gap: rem(32) 0;

  max-width: rem(330);

  &__item {
    padding-bottom: rem(30);

    border-bottom: rem(1) solid $border-color ;
  }

  &__item-content {
    display: grid;
    grid-gap: rem(16) 0;
  }

  &__item-link {
    position: relative;

    padding: 0 0 0 rem(10);

    font-weight: 400;
    font-size: rem(14);
    line-height: 1.2;
    color: $tertiary-dark;

    &:before {
      position: absolute;
      inset: 50% auto auto rem(2);

      width: rem(3);
      height: rem(3);

      content: '';

      background: $primary;
      transform: translate(0, -50%);
    }
  }

  &__item-head {
    display: flex;

    margin-bottom: rem(30);

    font-weight: 300;
    font-size: rem(24);
    line-height: 142%;

    &.--mrg-b {
      margin-bottom: rem(20);
    }

    @include insert-icons {
      margin: auto rem(30) auto 0;

      font-size: rem(38);
      color: $primary;
    }

  }

  &__item-pic {
  }
}
