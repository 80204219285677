.tag-cloud {
  //display: inline-flex;
  //max-width: rem(250);

  &__link {
    display: inline;

    font-size: rem(12);
    font-weight: 600;
    word-break: break-word;
    color: $tertiary;
    opacity: 0.5;

    &:not(:first-child) {
      margin-left: rem(5);
    }

    &.--s1 {
      font-size: rem(30);
      color: $dark;
      opacity: 1;

    }

    &.--s2 {
      font-size: rem(28);
      color: $dark;
      opacity: 0.6;
    }

    &.--s3 {
      font-size: rem(26);
      color: $dark;
      opacity: 0.7;
    }

    &.--s4 {
      font-size: rem(24);
      color: $dark;
      opacity: 0.8;
    }

    &.--s5 {
      font-size: rem(20);
    }

    &.--s6 {
      font-size: rem(16);
    }

    &.--s7 {
      font-size: rem(14);
    }


    @include only-mouse {
      &:hover {
        color: $primary
      }
    }
  }
}
