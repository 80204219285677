.layout {
  z-index: 2;
}

.section {
  //@extend %glob-animation-to-up;

  --p-margin: 0 0 #{rem(40)};
  --p-max-width: #{rem(690)};

  padding: rem(60) 0;

  overflow: hidden;

  &.--right {
    --container-align-items: flex-end;

    margin-left: auto;

    text-align: right;
  }

  &.--center {
    --container-align-items:  center;
    --p-max-width: #{auto};
    text-align: center;
  }
}

.caption {
  margin: var(--caption-margin, 0 0 rem(24) 0);

  font-weight: 300;
  font-size: rem(32);
  line-height: 122%;

  color: currentColor;

  &.--h2 {
    font-size: rem(40);
  }

  &.--h3 {
    font-size: rem(28);
  }

  @include max-wide(phonesLg) {
    font-size: rem(48);

    &.--h2 {
      font-size: rem(32);
    }

    &.--h3 {
      font-size: rem(22);
    }
  }
}
