.logo {
  display: inline-flex;
  color: var(--logo-color, $dark);
  z-index: 1;

  min-height: rem(22);
  width: rem(136);
  pointer-events: none;

  path {
    fill: currentColor;

    &:last-of-type {
      fill: $primary;
    }
  }

  @include max-wide(phones) {
    width: rem(150);
  }
}
