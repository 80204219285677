.posts {
  --caption-margin: #{ 0 0 rem(40)};

  &__list {
    display: grid;
    grid-gap: rem(30);
  }

  &__foot {
    display: flex;
    justify-content: center;

    margin: rem(40) 0 0;
  }

  @include min-wide(tablet) {
    padding: 0 rem(60) 0 0;

    border-right: rem(1) solid $border-color;
  }
}
