.button {
  @extend %glob-animation-to-up;

  position: relative;
  z-index: 1;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  min-height: rem(40);
  padding: rem(14) rem(32);

  font-weight: 500;
  font-size: rem(16);
  text-transform: uppercase;

  border-radius: rem(50);

  transition: .5s linear;
  transition-property: background-color, border-color, color, transform;
  cursor: pointer;

  &:not(.--line) {
    position: relative;

    color: var(--button-color, $light);

    background: var(--button-background, linear-gradient(0deg, $primary 0%, $primary-dark 100%));
  }

  &.--line {
    color: var(--button-color, $primary);

    background: var(--button-background, transparent);
    box-shadow: inset rem(0) rem(0) 0 rem(1) $primary;
  }

  &.--mw-205{
    min-width: rem(205);
  }

  @include only-mouse {
    &:hover {
      --button-offset-right: #{rem(-9)};
      --button-offset-left: 0;
      --button-background: #{$primary-dark}
    }

    &:active {
      transform: translate(0, rem(8));
    }

    &.--line {
      &:hover {
        //--button-background: var(--button-hover-background, #{transparent});
        --button-color: var(--button-hover-color, #{$light});
      }
    }
  }
}
