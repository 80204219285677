.featured {
  display: grid;

  border: 1px solid $border-color;
  border-radius: $radius;
  background: $light;
  box-shadow: $sh-card;

  overflow: hidden;

  @include only-mouse {
    &:hover {
      --featured-img-scale: 1.1;
    }
  }

  &__pic {
    overflow: hidden;

    img {
      object-fit: cover;
      object-position: left center;

      width: 100%;

      transform: scale(var(--featured-img-scale, 1.05));
      transition: $transition-default;
    }
  }

  &__content {
    display: flex;
    flex-flow: column;

    padding: rem(60) rem(60) rem(40) rem(60);
  }

  &__description {
    font-weight: 300;
    font-size: rem(24);
    line-height: rem(34);
  }

  &__bottom {
    display: inline-flex;
    flex-flow: column;

    margin: auto 0 0;
  }

  &__links {
    margin: rem(12) 0 rem(14);
  }

  @include max-wide(phonesLg) {
    &__content {
      gap: rem(16);

      padding: rem(38) rem(38) rem(24);
    }
  }

  @include min-wide(tablet) {
    grid-template-columns: 1fr 1fr;
  }
}
