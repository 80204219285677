.post-card {
  display: grid;
  grid-gap: rem(8) rem(40);
  grid-auto-columns: max-content auto;

  padding: 0 0 rem(30);
  border-bottom: rem(1) solid $border-color;

  &__img {
    grid-area: 2 span / 1;

    min-width: rem(280);
    min-height: rem(180);
  }

  &__caption {
    grid-area: 1 / 2;

    font-weight: 300;
    font-size: rem(24);
    line-height: rem(34);
  }

  &__info {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    gap: rem(8);

    grid-area: 2 / 2;
  }

  @include max-wide(tablet) {
    grid-auto-columns: auto;

    &__img, &__caption, &__info {
      grid-area: auto;
    }

    &__img img {
      width: 100%;
    }
  }
}
