//* Map size devices for media query
$wideScreens: (
  phonesSm: 340px,
  phones: 480px,
  phonesLg: 640px,
  tablet: 768px,
  laptop: 992px,
  desktopSm: 1024px,
  desktopMd: 1170px,
  desktop: 1280px,
  notebook: 1366px,
  notebookLg: 1600px,
  fullHD: 1800px,
  retina: 2100px,
  retina4k: 3656px,
  retina5k: 5100px,
  retina8k: 7600px
);

$customContainer: true;
$containerLimit: rem(1200) + rem(40);
$containerOffset: calc(var(--container-gutter) * -1);
$contentGutter: rem(16) !default;
$contentGutterX2: rem(24) !default;
$contentGutterX3: rem(48) !default;
$contentGutterX4: rem(96) !default;
$sectionSpacer: var(--section-spacer, rem(70)) !default;
$radius: rem(6);
//$radiusNormal: rem(12);
//$radiusLarge: rem(16);

$themes-map: (
  default:(
    primary: #4CB649,
    primary-dark: #5DDF5A,
    secondary: #FF8D00,
    secondary-dark: #FFA400,
    tertiary: #889099,
    tertiary-dark:#323642,
    light: #ffffff,
    light-dark: #F7F7F7,
    dark: #22252E,
    base: var(--cl-dark),
    border: #D7D8D9,
    error: #F24458,
    //warning: #fffb53,
    //success: #00FFBC
  ),
);

$primary: var(--cl-primary);
$primary-dark: var(--cl-primary-dark);
$secondary: var(--cl-secondary);
$secondary-dark: var(--cl-secondary-dark);
$tertiary: var(--cl-tertiary);
$tertiary-dark: var(--cl-tertiary-dark);

$light: var(--cl-light);
$light-dark: var(--cl-light-dark);

$dark: var(--cl-dark);

$error: var(--cl-error);
//$warning: var(--cl-warning);
//$success: var(--cl-success);

$base-color: var(--cl-base);
$border-color: var(--cl-border);

//* transitions && animations
$transition-default: 0.44s linear !default;
$transition-border: border-color $transition-default !default;
$transition-shadow: box-shadow $transition-default !default;

$transition-start-delay: var(--transition-start-delay, 1.8s);
$transition-start-duration: var(--transition-start-duration, 1s);
$global-animation-state: var(--gm-play-animation, paused);
$transition-function: cubic-bezier(.2, .63, .59, .84); //cubic-bezier(0,.89,.36,1.22);

$base-font-color: $secondary !default;
$base-font-size: 16px !default;

//$font-family-primary: 'Montserrat', sans-serif;
$font-family-primary: 'Roboto', sans-serif;
;
//$font-family-alternative: 'Raleway', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
$font-family-secondary: 'Public Sans', sans-serif; //'Halenoir Compact';

// * shadows
$sh-card: rem(0) rem(8) rem(16) rgba(0, 0, 0, 0.101961);
$sh-text: rem(0) rem(1) rem(0) $light;


//* Icons
$font-path: '../assets/fonts' !default;

$icon-font-family: "glivera-icons" !default;
$icon-font-prefix: 'gli-';
$icon-font-path: $font-path + '/#{$icon-font-family}' !default;

$gli-search: "\e90d";
$gli-caret: "\e900";
$gli-soc-gp: "\e901";
$gli-wave: "\e902";
$gli-notif: "\e903";
$gli-soc-ln: "\e904";
$gli-soc-yt: "\e905";
$gli-close: "\e906";
$gli-soc-ts: "\e907";
$gli-soc-tw: "\e909";
$gli-soc-fb: "\e90a";
$gli-arrow-right: "\e90b";
$gli-arrow-left: "\e908";
$gli-comment: "\e90c";

$icons-font-map: (
  search:$gli-search,
  caret:$gli-caret,
  soc-gp:$gli-soc-gp,
  wave:$gli-wave,
  notif:$gli-notif,
  soc-ln:$gli-soc-ln,
  soc-yt:$gli-soc-yt,
  close:$gli-close,
  soc-ts:$gli-soc-ts,
  soc-tw:$gli-soc-tw,
  soc-fb:$gli-soc-fb,
  arrow-right:$gli-arrow-right,
  arrow-left:$gli-arrow-left,
  comment:$gli-comment
);


//* Scrolls
$scroll-size: var(--scroll-size, rem(5));
$scroll-border-radius: 0;
$scroll-thumb-color: $primary;
$scroll-thumb-hover-color: $primary-dark;
$scroll-track-color: $light;
$scroll-track-line: inset 0 0 0 0px $scroll-track-color;

//* cursor
$cursor-size: rem(30);
$cursor-color: $secondary;
$cursor-color-active: $primary-dark;

//* Loader
$loader-size: rem(48);

//* z-index
$z-index-step: 10 !default;
$z-index: (
  start: 10,
  middle: 50,
  header: 100,
  modal: 1000
) !default;
