@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,800;1,900&display=swap";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:where(:not(iframe, canvas, img, svg, video):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

*, :before, :after {
  box-sizing: border-box;
}

a, button {
  cursor: revert;
}

ol, ul, menu {
  list-style: none;
}

img {
  max-width: 100%;
}

table {
  border-collapse: collapse;
}

textarea {
  white-space: revert;
}

meter {
  -webkit-appearance: revert;
  appearance: revert;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

.button {
  --in-transform-transform-start: translate(0, 10vh);
  --in-transform-transform-end: translate(0, 0);
  animation: var(--glob-animation-prop, in-transform) var(--transition-start-delay, 1.8s) var(--transition-start-duration, 1s) cubic-bezier(.2, .63, .59, .84) 1 forwards;
}

@keyframes in-transform {
  0% {
    opacity: var(--in-transform-opacity-start, 0);
    transform: var(--in-transform-transform-start, translate(-100vw, 0));
  }

  100% {
    opacity: var(--in-transform-opacity-end, 1);
    transform: var(--in-transform-transform-end, translate(0, 0));
  }
}

@keyframes smooth-opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.gm-m-t {
  margin-top: 1rem;
}

.gm-m-y {
  margin: 1.5rem 0;
}

.gm-drop-shadow {
  filter: drop-shadow(.125rem .25rem .375rem var(--cl-dark) );
}

.gm-pointer {
  pointer-events: none;
}

.gm-blur {
  filter: blur(.625rem);
}

.gm-scroll-hide {
  overflow: hidden !important;
}

.gm-hide {
  display: none !important;
}

.gm-m-b {
  margin-bottom: 1.5rem;
}

@font-face {
  font-family: glivera-icons;
  src: url("glivera-icons.47f2067d.eot");
  src: url("glivera-icons.47f2067d.eot#iefix") format("embedded-opentype"), url("glivera-icons.950086a2.ttf") format("truetype"), url("glivera-icons.6d7dd181.woff") format("woff"), url("glivera-icons.7e4f6ce9.svg#glivera-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="gli-"], [class*=" gli-"] {
  speak: none;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: glivera-icons !important;
}

.gli-search:before {
  content: "";
}

.gli-caret:before {
  content: "";
}

.gli-soc-gp:before {
  content: "";
}

.gli-wave:before {
  content: "";
}

.gli-notif:before {
  content: "";
}

.gli-soc-ln:before {
  content: "";
}

.gli-soc-yt:before {
  content: "";
}

.gli-close:before {
  content: "";
}

.gli-soc-ts:before {
  content: "";
}

.gli-soc-tw:before {
  content: "";
}

.gli-soc-fb:before {
  content: "";
}

.gli-arrow-right:before {
  content: "";
}

.gli-arrow-left:before {
  content: "";
}

.gli-comment:before {
  content: "";
}

:root {
  --container-limit: 77.5rem;
  --container-gutter: 1.25rem;
  --header-height: 7.5rem;
  --cl-primary: #4cb649;
  --cl-primary-dark: #5ddf5a;
  --cl-secondary: #ff8d00;
  --cl-secondary-dark: #ffa400;
  --cl-tertiary: #889099;
  --cl-tertiary-dark: #323642;
  --cl-light: #fff;
  --cl-light-dark: #f7f7f7;
  --cl-dark: #22252e;
  --cl-base: var(--cl-dark);
  --cl-border: #d7d8d9;
  --cl-error: #f24458;
}

@media (max-width: 639px) {
  :root {
    --container-gutter: 1.5rem;
  }
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  text-align: left;
  letter-spacing: normal;
  color: var(--cl-base);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  scroll-behavior: smooth;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 140%;
}

html[loading="true"]:before {
  z-index: 1010;
  width: 3rem;
  height: 3rem;
  content: "";
  color: var(--cl-primary);
  border: .125rem solid #0000;
  border-color: currentColor #0000 #0000 currentColor;
  border-radius: 50%;
  animation: rotate .4s linear infinite;
  position: fixed;
  top: calc(50% - 1.5rem) !important;
  left: calc(50% - 1.5rem) !important;
}

html[loading="true"]:after {
  content: "Glivera team loading page...";
  color: var(--cl-light);
  white-space: nowrap;
  background-color: var(--cl-primary);
  border-radius: .375rem .375rem 0 0;
  padding: .125rem .5rem 0;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.4;
  display: inline-flex;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

html[loading="true"] body {
  visibility: hidden;
  animation: stop;
}

html[loading="false"] {
  --gm-play-animation: start;
}

@media (max-width: 1160px) {
  html {
    font-size: 75%;
  }
}

@media (max-width: 480px) {
  html {
    font-size: 82%;
  }
}

@media (max-width: 380px) {
  html {
    font-size: 76%;
  }
}

@media (max-width: 340px) {
  html {
    font-size: 66%;
  }
}

body {
  min-width: 100vw;
  min-height: 100vh;
  padding: calc(var(--header-top, 0)  + var(--header-height) ) 0 0;
  background: var(--main-bg, var(--cl-light));
  animation: smooth-opacity var(--transition-start-duration, 1s) 0s cubic-bezier(.2, .63, .59, .84) 1 forwards;
  animation-play-state: var(--gm-play-animation, paused);
  flex-flow: column;
  margin: 0;
  display: flex;
}

a {
  color: var(--link-color, currentColor);
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  transition: color .44s linear;
}

@media (pointer: fine) {
  a:hover {
    color: var(--link-hover-color, var(--cl-secondary));
  }
}

b, strong {
  font-weight: bold;
}

mark {
  color: currentColor;
  background: unset;
}

p {
  max-width: var(--p-max-width, unset);
  margin: var(--p-margin, 0);
  font-weight: normal;
  font-size: var(--p-font-size, 1rem);
  line-height: 140%;
}

::selection {
  background-color: var(--cl-secondary);
  color: var(--cl-light);
}

.container {
  position: var(--container-position, unset);
  display: var(--container-block, flex);
  align-items: var(--container-align-items, flex-start);
  justify-content: var(--container-justify-content, flex-start);
  flex-flow: var(--container-flex-flow, column);
  width: 100%;
  max-width: var(--container-limit, auto);
  padding-right: var(--container-gutter, 0);
  padding-left: var(--container-gutter, 0);
  margin-left: auto;
  margin-right: auto;
}

@media (max-height: 420px) {
  @supports (padding: max(0px)) {
    .container {
      padding-left: max(16px, env(safe-area-inset-left) );
      padding-right: max(16px, env(safe-area-inset-right) );
    }
  }
}

.button {
  z-index: 1;
  min-height: 2.5rem;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 3.125rem;
  justify-content: center;
  align-items: center;
  padding: .875rem 2rem;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color .5s linear, border-color .5s linear, color .5s linear, transform .5s linear;
  display: inline-flex;
  position: relative;
}

.button:not(.--line) {
  color: var(--button-color, var(--cl-light));
  background: var(--button-background, linear-gradient(0deg, var(--cl-primary) 0%, var(--cl-primary-dark) 100%));
  position: relative;
}

.button.--line {
  color: var(--button-color, var(--cl-primary));
  background: var(--button-background, transparent);
  box-shadow: inset 0rem 0rem 0 .0625rem var(--cl-primary);
}

.button.--mw-205 {
  min-width: 12.8125rem;
}

@media (pointer: fine) {
  .button:hover {
    --button-offset-right: -.5625rem;
    --button-offset-left: 0;
    --button-background: var(--cl-primary-dark);
  }

  .button:active {
    transform: translate(0, .5rem);
  }

  .button.--line:hover {
    --button-color: var(--button-hover-color, var(--cl-light));
  }
}

.logo {
  color: var(--logo-color, var(--cl-dark));
  z-index: 1;
  min-height: 1.375rem;
  width: 8.5rem;
  pointer-events: none;
  display: inline-flex;
}

.logo path {
  fill: currentColor;
}

.logo path:last-of-type {
  fill: var(--cl-primary);
}

@media (max-width: 479px) {
  .logo {
    width: 9.375rem;
  }
}

.category-link {
  letter-spacing: .03125rem;
  color: var(--cl-primary);
  text-transform: uppercase;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1rem;
}

.category-link:not(:last-of-type):after {
  content: ", ";
}

.comment-counter {
  color: var(--cl-tertiary);
  align-items: center;
  gap: .625rem;
  font-size: .875rem;
  font-weight: 400;
  display: inline-flex;
}

.comment-counter:before {
  content: "";
  font-family: glivera-icons;
  font-size: .875rem;
  line-height: 1;
}

.author-time {
  align-items: center;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.375rem;
  display: inline-flex;
}

.author-time:after {
  content: "| " attr(data-time);
  color: var(--cl-tertiary);
  margin: auto .5rem;
}

.layout {
  z-index: 2;
}

.section {
  --p-margin: 0 0 2.5rem;
  --p-max-width: 43.125rem;
  padding: 3.75rem 0;
  overflow: hidden;
}

.section.--right {
  --container-align-items: flex-end;
  text-align: right;
  margin-left: auto;
}

.section.--center {
  --container-align-items: center;
  --p-max-width: auto;
  text-align: center;
}

.caption {
  margin: var(--caption-margin, 0 0 1.5rem 0);
  color: currentColor;
  font-size: 2rem;
  font-weight: 300;
  line-height: 122%;
}

.caption.--h2 {
  font-size: 2.5rem;
}

.caption.--h3 {
  font-size: 1.75rem;
}

@media (max-width: 639px) {
  .caption {
    font-size: 3rem;
  }

  .caption.--h2 {
    font-size: 2rem;
  }

  .caption.--h3 {
    font-size: 1.375rem;
  }
}

.header {
  --container-align-items: center;
  --container-flex-flow: row;
  --button-hover-background: var(--cl-light);
  --button-hover-color: var(--cl-primary);
  --in-transform-transform-start: translate(0, -5vh);
  inset: var(--header-top, 0) 0 auto;
  z-index: 100;
  width: 100%;
  height: var(--header-height);
  background: var(--header-background, var(--cl-light));
  opacity: 0;
  animation: in-transform var(--transition-start-delay, 1.8s) var(--transition-start-duration, 1s) cubic-bezier(.17, .67, .83, .67) 1 forwards;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 0;
  transition: background-color .44s linear, color .44s linear, padding .44s linear, height .44s linear;
  display: flex;
  position: fixed;
}

.header__btn-menu {
  z-index: 11;
  width: 2.25rem;
  height: 2.25rem;
  color: currentColor;
  cursor: pointer;
  -webkit-appearence: none;
  -webkit-tap-highlight-color: transparent;
  background: none;
  border: none;
  outline: none;
  margin: 0 1.5rem 0 0;
  padding: 0;
  position: relative;
}

.header__btn-menu svg {
  width: 4rem;
  height: 3rem;
  stroke: var(--header-color, currentColor);
  stroke-width: 4px;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header__btn-menu svg path {
  transition: stroke-dasharray var(--duration, .85s) var(--easing, ease) var(--delay, 0s), stroke-dashoffset var(--duration, .85s) var(--easing, ease) var(--delay, 0s);
  stroke-dasharray: var(--array-1, 26px) var(--array-2, 100px);
  stroke-dashoffset: var(--offset, 126px);
  transform: translateZ(0);
}

.header__btn-menu svg path:nth-child(2) {
  --duration: .7s;
  --easing: ease-in;
  --offset: 100px;
  --array-2: 74px;
}

.header__btn-menu svg path:nth-child(3) {
  --offset: 133px;
  --array-2: 107px;
}

.header__btn-menu.--is-active {
  --header-color: var(--cl-primary);
}

.header__btn-menu.--is-active svg path {
  --offset: 57px;
}

.header__btn-menu.--is-active svg path:nth-child(1), .header__btn-menu.--is-active svg path:nth-child(3) {
  --delay: .15s;
  --easing: cubic-bezier(.2, .4, .2, 1.1);
}

.header__btn-menu.--is-active svg path:nth-child(2) {
  --duration: .4s;
  --offset: 2px;
  --array-1: 1px;
}

.header__btn-menu.--is-active svg path:nth-child(3) {
  --offset: 58px;
}

.header__menu {
  height: max-content;
  background: var(--header-background, var(--cl-light));
  flex-flow: row;
  align-items: center;
  gap: 1.875rem;
  margin: auto;
  display: inline-flex;
  position: relative;
}

.header__menu-sub {
  --header-link-content: none;
  grid-gap: .375rem;
  background: var(--header-background, var(--cl-light));
  opacity: 0;
  visibility: hidden;
  border-radius: .375rem;
  align-items: center;
  margin-top: -.125rem;
  padding: 1rem;
  transition: transform .4s linear, opacity .4s linear;
  display: inline-grid;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -1vh);
  box-shadow: 0 .5rem 1rem #0000001a;
}

.header__link {
  letter-spacing: .05rem;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  align-items: center;
  font-size: .875rem;
  font-weight: 500;
  line-height: 2.5;
  display: inline-flex;
  position: relative;
}

.header__link:before {
  top: 100%;
  left: 0;
  right: var(--header-link-right, 100%);
  z-index: -1;
  height: .1875rem;
  content: var(--header-link-content, "");
  background: var(--cl-primary-dark);
  transition: right .5s linear;
  position: absolute;
}

.header__link.--caret:hover .header__menu-sub, .header__link.--caret:active .header__menu-sub {
  --link-color: var(--cl-dark);
  --link-hover-color: var(--cl-secondary);
  opacity: 1;
  visibility: visible;
  transform: translate(-50%);
}

.header__link.--caret:after {
  content: "";
  font-family: glivera-icons;
  font-size: .625rem;
  line-height: 1;
  transform: translate(50%);
}

.header__link.--is-active {
  --header-link-right: 0;
}

@media (pointer: fine) {
  .header__link:hover {
    --header-link-right: 0;
    color: var(--cl-primary);
  }
}

.header__btns {
  gap: .625rem;
  display: flex;
}

@media (max-width: 991px) {
  .header {
    --header-link-margin: 4%;
    --header-link-last-margin: 2.375rem;
  }

  .header__menu {
    flex-direction: column;
  }

  .header__menu:not(.--is-active) {
    display: none;
  }

  .header__menu.--is-active {
    inset: var(--header-height) 0 auto;
    z-index: 100;
    padding: 1.5rem;
    position: absolute;
  }

  .header__btns {
    margin-left: auto;
  }
}

@media (max-width: 639px) {
  .header .button.--line {
    display: none;
  }
}

@media (min-width: 992px) {
  .header__btn-menu {
    display: none;
  }
}

.footer {
  background: var(--cl-light);
  padding: 5.25rem 0 5.625rem;
}

.footer__main {
  grid-gap: 0 2.875rem;
  grid-template: repeat(auto-fit, 2.5rem) / 1fr 1fr 1fr 4.1fr;
  margin: 3.75rem 0 0;
  display: grid;
}

.footer__item {
  --p-font-size: .875rem;
  max-width: 36.25rem;
  color: var(--cl-tertiary);
  flex-flow: column;
  place-self: flex-start;
  gap: .625rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 157%;
  display: flex;
}

.footer__item:nth-child(1) {
  grid-area: var(--footer-item-1-area, 8 span / 1);
}

.footer__item:nth-child(2) {
  grid-area: var(--footer-item-2-area, 5 span / 2 / 1 / 2);
}

.footer__item:nth-child(3) {
  grid-area: var(--footer-item-3-area, 5 span / 2 / 5 / 2);
}

.footer__item:nth-child(4) {
  grid-area: var(--footer-item-4-area, 8 span / 3);
}

.footer__item:nth-child(5) {
  order: var(--footer-item-5-order, unset);
  grid-area: var(--footer-item-5-area, 7 span / 4);
}

.footer__item:nth-child(6) {
  order: var(--footer-item-6-order, unset);
  grid-area: var(--footer-item-6-area, 2 span / 4);
}

.footer__item.--soc {
  max-width: var(--footer-soc-mx, 20.625rem);
  flex-flow: wrap;
  column-gap: 1.875rem;
}

.footer__item.--soc [class*="gli-"] {
  color: var(--cl-dark);
  transform-origin: center;
  font-size: 1.75rem;
  transition: transform .6s ease-out, color .6s ease-out;
}

@media (pointer: fine) {
  .footer__item.--soc [class*="gli-"]:hover {
    color: var(--cl-primary);
    transform: scale(2)rotate(-360deg);
  }
}

.footer__item.--offset-top {
  margin-top: 1.875rem;
}

.footer__item span {
  font-size: .75rem;
  line-height: 133%;
}

.footer__capt {
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--cl-dark);
  flex: 100%;
  margin: 0 0 .625rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 129%;
}

.footer__link {
  white-space: nowrap;
}

@media (max-width: 767px) {
  .footer__main {
    --footer-item-1-area: 2 span / 1;
    --footer-item-2-area: 1 / 2;
    --footer-item-3-area: 2 / 2;
    --footer-item-4-area: 3 span / 1;
    --footer-item-6-area: 3 / 2;
    --footer-item-5-area: 6 / 2 span;
    --footer-soc-mx: 10.3125rem;
    grid-gap: 2.5rem 1.5rem;
    grid-template-columns: 1fr 1fr;
  }
}

.blog {
  --in-transform-transform-start: translate(0, 5vh);
  grid-gap: 3rem;
  opacity: 0;
  animation: in-transform var(--transition-start-delay, 1.8s) var(--transition-start-duration, 1s) cubic-bezier(.17, .67, .83, .67) 1 forwards;
  padding: 0 0 3rem;
  display: grid;
}

@media (max-width: 767px) {
  .blog__tags, .blog__aside {
    display: none;
  }

  .blog__top {
    order: 1;
  }

  .blog__search {
    order: 2;
  }

  .blog__posts {
    order: 3;
  }
}

@media (min-width: 768px) {
  .blog {
    grid-gap: 2.5rem 3rem;
    grid-template-columns: repeat(12, 1fr);
  }

  .blog__top {
    grid-area: 1 / 12 span;
  }

  .blog__tags {
    grid-area: 2 / 12 span;
  }

  .blog__posts {
    grid-area: 2 span / 9 span / 5;
  }

  .blog__search {
    grid-area: 3 / 3 span;
  }

  .blog__aside {
    grid-area: 4 / 3 span;
  }
}

.alert {
  --link-color: var(--cl-light);
  z-index: 110;
  padding: .625rem var(--container-gutter);
  color: var(--cl-tertiary);
  min-height: var(--alert-height, 2.5rem);
  background: var(--cl-dark);
  opacity: 0;
  justify-content: center;
  align-items: center;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1rem;
  transition: all .44s linear;
  display: flex;
  position: fixed;
  top: 0;
  bottom: auto;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.alert.--is-show {
  opacity: 1;
  transform: none;
}

.alert__wrap {
  grid-gap: 2rem;
  grid-auto-flow: column;
  align-items: center;
  display: grid;
}

.alert__warn {
  color: var(--cl-secondary);
  align-items: center;
  gap: .25rem;
  font-weight: bold;
  display: inline-flex;
}

.alert__close {
  font-size: .875rem;
  position: absolute;
  top: 50%;
  right: 1.25rem;
  transform: translateY(-50%);
}

@media (pointer: fine) {
  .alert__close:hover {
    color: var(--cl-secondary);
  }
}

@media (max-width: 639px) {
  .alert {
    justify-content: initial;
    --alert-grid-area-1: 1 / 1;
  }

  .alert__wrap {
    grid-gap: 1.25rem;
    width: 100%;
    margin-right: 1.5rem;
  }

  .alert__link {
    text-align: right;
    grid-area: 1 / 2;
  }

  .alert__item:nth-of-type(2) {
    grid-area: 2 / 1;
  }

  .alert__item:nth-of-type(1) {
    grid-area: 2 / 2;
  }
}

.featured {
  border: 1px solid var(--cl-border);
  background: var(--cl-light);
  border-radius: .375rem;
  display: grid;
  overflow: hidden;
  box-shadow: 0 .5rem 1rem #0000001a;
}

@media (pointer: fine) {
  .featured:hover {
    --featured-img-scale: 1.1;
  }
}

.featured__pic {
  overflow: hidden;
}

.featured__pic img {
  object-fit: cover;
  object-position: left center;
  width: 100%;
  transform: scale(var(--featured-img-scale, 1.05) );
  transition: all .44s linear;
}

.featured__content {
  flex-flow: column;
  padding: 3.75rem 3.75rem 2.5rem;
  display: flex;
}

.featured__description {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2.125rem;
}

.featured__bottom {
  flex-flow: column;
  margin: auto 0 0;
  display: inline-flex;
}

.featured__links {
  margin: .75rem 0 .875rem;
}

@media (max-width: 639px) {
  .featured__content {
    gap: 1rem;
    padding: 2.375rem 2.375rem 1.5rem;
  }
}

@media (min-width: 768px) {
  .featured {
    grid-template-columns: 1fr 1fr;
  }
}

.tags {
  text-transform: uppercase;
  color: var(--cl-dark);
  letter-spacing: .0625rem;
  background: var(--cl-light-dark);
  border-radius: .25rem;
  align-items: center;
  gap: 2.5rem;
  padding: 1.3125rem 2.0625rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.125rem;
  display: flex;
}

.tags__links {
  gap: 2rem;
  margin: auto auto auto 0;
  display: inline-flex;
}

.tags__tag {
  letter-spacing: .05rem;
  color: var(--cl-primary);
}

.pimp-paln {
  --container-align-items: center;
  text-align: center;
  background: var(--cl-dark);
  padding: 6.25rem 0 3.5625rem;
}

.pimp-paln__capt {
  letter-spacing: -.03125rem;
  color: var(--cl-light);
  margin: auto auto 2.5rem;
  font-size: 3rem;
  font-weight: 300;
  line-height: 133%;
}

.pimp-paln__items {
  width: 100%;
  justify-content: stretch;
  align-items: center;
  gap: 1.25rem;
  display: flex;
}

.pimp-paln__item {
  min-width: 23.75rem;
  height: 21.25rem;
  background: var(--cl-light);
  border: .25rem solid #0000;
  border-radius: .25rem;
  flex-flow: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
  font-size: .875rem;
  font-weight: 400;
  display: flex;
}

.pimp-paln__item.--select {
  height: 23.75rem;
  border-color: var(--cl-primary);
}

.pimp-paln__item-capt {
  text-transform: uppercase;
  letter-spacing: 1px;
  flex-flow: column;
  font-weight: 700;
  display: inline-flex;
}

.pimp-paln__item-capt:after {
  width: 1.5rem;
  height: .125rem;
  content: "";
  background: var(--cl-primary);
  border-radius: .1875rem;
  margin: .875rem auto;
}

.pimp-paln__item-price {
  letter-spacing: -.09375rem;
  gap: .125rem;
  font-size: 3.5rem;
  font-weight: 300;
  display: inline-flex;
}

.pimp-paln__item-price:before {
  content: "$";
  align-self: flex-start;
  font-size: 1.5rem;
}

.pimp-paln__item-price:after {
  content: "/mo.";
  align-self: flex-end;
  font-size: 1.5rem;
}

.pimp-paln__item-desc {
  width: 100%;
  max-width: 12.5rem;
  min-height: 3rem;
  border-top: .0625rem solid var(--cl-border);
  flex-flow: wrap;
  justify-content: center;
  align-self: center;
  gap: .5rem;
  margin-top: 1.125rem;
  padding: 1rem 0 1.5rem;
  font-size: .875rem;
  display: inline-flex;
}

.pimp-paln__item-desc span {
  color: var(--cl-error);
  margin-left: .5rem;
  text-decoration: line-through;
}

.pimp-paln__item-desc strong {
  flex: 1;
}

.pimp-paln__payments {
  color: var(--cl-light);
  justify-content: center;
  align-items: center;
  margin: 3.375rem auto 2.0625rem;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.75rem;
  display: flex;
}

.pimp-paln__logos {
  justify-content: center;
  align-items: center;
  gap: 1.75rem;
  margin-left: 1.875rem;
  display: flex;
}

.pimp-paln__logos img {
  max-width: 6.25rem;
  height: 3.75rem;
  object-fit: contain;
  object-position: center center;
  display: inline-flex;
}

.pimp-paln__links {
  grid-auto-flow: column;
  gap: 2.75rem;
  display: inline-grid;
}

.pimp-paln__link {
  color: var(--cl-primary);
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  display: inline-flex;
}

.pimp-paln__link:after {
  content: "";
  margin: auto 0 auto 1.25rem;
  font-family: glivera-icons;
  font-size: .9375rem;
  line-height: 1;
}

@media (max-width: 767px) {
  .pimp-paln__payments, .pimp-paln__items {
    flex-flow: column;
  }

  .pimp-paln__links {
    grid-auto-flow: row;
    gap: 1.5rem;
  }

  .pimp-paln__logos {
    flex-flow: wrap;
    margin: 1.5rem 0;
  }
}

.aside {
  grid-gap: 2rem 0;
  max-width: 20.625rem;
  display: grid;
}

.aside__item {
  border-bottom: .0625rem solid var(--cl-border);
  padding-bottom: 1.875rem;
}

.aside__item-content {
  grid-gap: 1rem 0;
  display: grid;
}

.aside__item-link {
  color: var(--cl-tertiary-dark);
  padding: 0 0 0 .625rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.2;
  position: relative;
}

.aside__item-link:before {
  width: .1875rem;
  height: .1875rem;
  content: "";
  background: var(--cl-primary);
  position: absolute;
  top: 50%;
  bottom: auto;
  left: .125rem;
  right: auto;
  transform: translate(0, -50%);
}

.aside__item-head {
  margin-bottom: 1.875rem;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 142%;
  display: flex;
}

.aside__item-head.--mrg-b {
  margin-bottom: 1.25rem;
}

.aside__item-head [class*="gli-"] {
  color: var(--cl-primary);
  margin: auto 1.875rem auto 0;
  font-size: 2.375rem;
}

.posts {
  --caption-margin: 0 0 2.5rem;
}

.posts__list {
  grid-gap: 1.875rem;
  display: grid;
}

.posts__foot {
  justify-content: center;
  margin: 2.5rem 0 0;
  display: flex;
}

@media (min-width: 768px) {
  .posts {
    border-right: .0625rem solid var(--cl-border);
    padding: 0 3.75rem 0 0;
  }
}

.post-card {
  grid-gap: .5rem 2.5rem;
  border-bottom: .0625rem solid var(--cl-border);
  grid-auto-columns: max-content auto;
  padding: 0 0 1.875rem;
  display: grid;
}

.post-card__img {
  min-width: 17.5rem;
  min-height: 11.25rem;
  grid-area: 2 span / 1;
}

.post-card__caption {
  grid-area: 1 / 2;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2.125rem;
}

.post-card__info {
  flex-flow: column;
  grid-area: 2 / 2;
  justify-content: flex-end;
  gap: .5rem;
  display: flex;
}

@media (max-width: 767px) {
  .post-card {
    grid-auto-columns: auto;
  }

  .post-card__img, .post-card__caption, .post-card__info {
    grid-area: auto;
  }

  .post-card__img img {
    width: 100%;
  }
}

.search {
  align-items: center;
  display: flex;
  position: relative;
}

.search:after {
  content: "";
  padding: 0 1.25rem;
  font-family: glivera-icons;
  font-size: .9375rem;
  line-height: 1;
  position: absolute;
}

.search input {
  width: 100%;
  height: 3.6875rem;
  border: .0625rem solid var(--cl-border);
  border-radius: .25rem;
  padding: 1rem 1.25rem 1rem 3.4375rem;
  font-size: 1.125rem;
  font-weight: 500;
}

.search input:focus {
  border-color: var(--cl-primary);
}

@media (pointer: fine) {
  .search input:hover {
    border-color: var(--cl-primary);
  }

  .search input:hover ~ * {
    color: var(--cl-primary);
  }
}

.search input::-webkit-input-placeholder {
  opacity: .8;
  font-weight: 300;
}

.search input:-moz-placeholder {
  opacity: .8;
  font-weight: 300;
}

.search input::-moz-placeholder {
  opacity: .8;
  font-weight: 300;
}

.search input:-ms-input-placeholder {
  opacity: .8;
  font-weight: 300;
}

.tag-cloud__link {
  word-break: break-word;
  color: var(--cl-tertiary);
  opacity: .5;
  font-size: .75rem;
  font-weight: 600;
  display: inline;
}

.tag-cloud__link:not(:first-child) {
  margin-left: .3125rem;
}

.tag-cloud__link.--s1 {
  color: var(--cl-dark);
  opacity: 1;
  font-size: 1.875rem;
}

.tag-cloud__link.--s2 {
  color: var(--cl-dark);
  opacity: .6;
  font-size: 1.75rem;
}

.tag-cloud__link.--s3 {
  color: var(--cl-dark);
  opacity: .7;
  font-size: 1.625rem;
}

.tag-cloud__link.--s4 {
  color: var(--cl-dark);
  opacity: .8;
  font-size: 1.5rem;
}

.tag-cloud__link.--s5 {
  font-size: 1.25rem;
}

.tag-cloud__link.--s6 {
  font-size: 1rem;
}

.tag-cloud__link.--s7 {
  font-size: .875rem;
}

@media (pointer: fine) {
  .tag-cloud__link:hover {
    color: var(--cl-primary);
  }
}

.about-me {
  --link-hover-color: var(--cl-dark);
  z-index: 1010;
  position: fixed;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
}

.about-me__wrap {
  z-index: 1;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 0 0 .4375rem var(--cl-light);
  background: #22252ee6;
  align-items: center;
  gap: 3rem;
  padding: 3rem;
  display: flex;
  position: relative;
}

.about-me__main {
  gap: .625rem;
  display: inline-grid;
}

.about-me__caption {
  color: var(--cl-light);
  flex-flow: column;
  margin: 0 0 1.5rem;
  font-size: 2.375rem;
  font-weight: 800;
  display: inline-flex;
}

.about-me__img {
  width: 11.25rem;
  min-width: 11.25rem;
  height: 11.25rem;
  min-height: 11.25rem;
  box-shadow: 0 0 0 .4375rem var(--cl-light);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  overflow: hidden;
}

.about-me__links {
  color: var(--cl-light);
  align-items: center;
  gap: 1.25rem;
  font-weight: 500;
  display: inline-flex;
}

.about-me__links mark {
  min-width: 6.25rem;
  font-weight: 700;
  display: inline-flex;
}

.about-me__link {
  text-transform: uppercase;
  color: var(--cl-light);
  letter-spacing: .0625rem;
  text-align: center;
  background: none;
  border: .125rem solid;
  border-radius: .25rem;
  margin: 0;
  padding: .25rem .5rem;
  font-size: .8125rem;
  font-weight: 700;
}

.about-me__link:hover {
  color: var(--cl-primary);
  border-color: var(--cl-light);
  background: var(--cl-light);
}

.about-me__canvas {
  z-index: -1;
  width: 18.75rem;
  height: 9.375rem;
  opacity: .8;
  position: absolute;
  top: auto;
  bottom: 3rem;
  left: auto;
  right: 3rem;
}

.about-me__close {
  z-index: -1;
  background: #4cb64999;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.about-me__close span {
  max-width: 25rem;
  letter-spacing: 1px;
  color: var(--cl-light);
  text-align: center;
  filter: drop-shadow(0 0 .625rem var(--cl-dark) );
  margin-top: -10%;
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 1.5;
}

/*# sourceMappingURL=index.4bf2541a.css.map */
