.footer {
  padding: rem(84) 0 rem(90);

  background: $light;

  &__top {
  }

  &__main {
    display: grid;
    grid-template: repeat(auto-fit, rem(40)) / 1fr 1fr 1fr 4.1fr;
    grid-gap: 0 rem(46);
    //grid-template: repeat(auto-fit, rem(40)) / 1fr 1fr 1fr 4.1fr;
    //grid-auto-rows: rem(40);

    margin: rem(60) 0 0;
  }

  &__item {
    --p-font-size: #{rem(14)};

    display: flex;
    flex-flow: column;
    gap: rem(10);
    place-self: flex-start flex-start;

    max-width: rem(580);

    font-weight: 400;
    font-size: rem(14);
    line-height: 157%;
    color: $tertiary;

    &:nth-child(1) {
      grid-area: var(--footer-item-1-area, 8 span / 1);
    }

    &:nth-child(2) {
      grid-area: var(--footer-item-2-area, 5 span / 2 / 1 / 2);
    }

    &:nth-child(3) {
      grid-area: var(--footer-item-3-area, 5 span / 2 / 5 / 2);
    }

    &:nth-child(4) {
      grid-area: var(--footer-item-4-area, 8 span / 3);
    }

    &:nth-child(5) {
      order: var(--footer-item-5-order, unset);
      grid-area: var(--footer-item-5-area, 7 span / 4);
    }

    &:nth-child(6) {
      order: var(--footer-item-6-order, unset);
      grid-area: var(--footer-item-6-area, 2 span / 4)
    }

    &.--soc {
      flex-flow: row wrap;
      column-gap: rem(30);

      max-width: var(--footer-soc-mx, rem(330));

      @include insert-icons {
        font-size: rem(28);
        color: $dark;

        transition: .6s ease-out;
        transition-property: transform, color;
        transform-origin: center center;

        @include only-mouse {
          &:hover {
            color: $primary;

            transform: scale(2) rotate(-360deg);
          }
        }
      }
    }

    &.--offset-top {
      margin-top: rem(30);
    }

    span {
      font-size: rem(12);
      line-height: 133%;
    }
  }

  &__capt {
    flex: 1 1 100%;

    margin: 0 0 rem(10);

    font-weight: 500;
    font-size: rem(14);
    line-height: 129%;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $dark;
  }

  &__link {
    white-space: nowrap;
  }

  @include max-wide(tablet) {
    &__main {
      --footer-item-1-area: 2 span /1;
      --footer-item-2-area: 1 /2;
      --footer-item-3-area: 2 /2;
      --footer-item-4-area: 3 span /1;
      --footer-item-6-area: 3/2;
      --footer-item-5-area: 6/2 span;

      --footer-soc-mx: #{rem(165)};

      grid-template-columns: 1fr 1fr;
      grid-gap: rem(40) rem(24);
    }
  }
}
