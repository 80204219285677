.header {
  --container-align-items: center;
  --container-flex-flow: row;

  --button-hover-background: #{$light};
  --button-hover-color: #{$primary};
  --in-transform-transform-start: translate(0, -5vh);

  position: fixed;
  inset: var(--header-top,0) 0 auto;
  z-index: z-index(header);

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: var(--header-height);

  padding: rem(20) 0;

  background: var(--header-background, $light);

  transition: $transition-default;
  transition-property: background-color, color, padding, height;

  opacity: 0;

  animation: in-transform $transition-start-delay $transition-start-duration cubic-bezier(.17, .67, .83, .67) 1 forwards;

  &__btn-menu {
    position: relative;
    z-index: $z-index-step + 1;

    width: rem(36);
    height: rem(36);

    padding: 0;
    margin: 0 rem(24) 0 0;

    outline: none;
    color: currentColor;

    border: none;
    background: none;
    cursor: pointer;

    -webkit-appearence: none;
    -webkit-tap-highlight-color: transparent;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;

      display: block;

      width: rem(64);
      height: rem(48);

      stroke: var(--header-color, currentColor);
      stroke-width: 4px;
      stroke-linecap: round;
      stroke-linejoin: round;

      fill: none;

      transform: translate(-50%, -50%);

      path {
        transition: stroke-dasharray var(--duration, .85s) var(--easing, ease) var(--delay, 0s), stroke-dashoffset var(--duration, .85s) var(--easing, ease) var(--delay, 0s);
        stroke-dasharray: var(--array-1, 26px) var(--array-2, 100px);
        stroke-dashoffset: var(--offset, 126px);
        transform: translateZ(0);

        &:nth-child(2) {
          --duration: .7s;
          --easing: ease-in;
          --offset: 100px;
          --array-2: 74px;
        }

        &:nth-child(3) {
          --offset: 133px;
          --array-2: 107px;
        }
      }
    }

    &.--is-active {
      --header-color: #{$primary};

      svg {
        path {
          --offset: 57px;

          &:nth-child(1),
          &:nth-child(3) {
            --delay: .15s;
            --easing: cubic-bezier(.2, .4, .2, 1.1);
          }

          &:nth-child(2) {
            --duration: .4s;
            --offset: 2px;
            --array-1: 1px;
          }

          &:nth-child(3) {
            --offset: 58px;
          }
        }
      }
    }
  }

  &__menu {
    position: relative;

    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: rem(30);

    margin: auto;

    height: max-content;

    background: var(--header-background, $light);
  }

  &__menu-sub {
    --header-link-content: none;

    position: absolute;
    top: 100%;
    left: 50%;
    //z-index: -1;

    display: inline-grid;
    grid-gap: rem(6);
    align-items: center;

    padding: rem(16);
    margin-top: rem(-2);

    border-radius: $radius;
    background: var(--header-background, $light);
    box-shadow: $sh-card;

    transform: translate(-50%, -1vh);
    opacity: 0;
    visibility: hidden;

    transition: .4s linear;
    transition-property: transform, opacity;
  }

  &__link {
    position: relative;

    display: inline-flex;
    align-items: center;

    font-size: rem(14);
    font-weight: 500;
    letter-spacing: rem(0.8);
    line-height: 2.5;
    white-space: nowrap;
    text-transform: uppercase;

    cursor: pointer;

    &:before {
      position: absolute;
      top: 100%;
      left: 0;
      right: var(--header-link-right, 100%);
      z-index: -1;

      height: rem(3);

      content: var(--header-link-content, '');

      background: $primary-dark;

      transition: right .5s linear;
    }

    &.--caret {
      &:hover, &:active {
        .header__menu-sub {
          --link-color: #{$dark};
          --link-hover-color: #{$secondary};

          transform: translate(-50%, 0);
          opacity: 1;

          visibility: visible;
        }
      }

      &:after {
        @include pseudo-icon($gli-caret, 10);

        transform: translate(50%, 0);
      }
    }

    &.--is-active {
      --header-link-right: 0;
    }

    @include only-mouse {
      &:hover {
        --header-link-right: 0;

        color: $primary;
      }
    }
  }

  &__btns {
    display: flex;
    gap: rem(10);
  }

  @include max-wide(laptop) {
    --header-link-margin: 4%;
    --header-link-last-margin: #{rem(38)};

    &__menu {
      flex-direction: column;

      &:not(.--is-active) {
        display: none;
      }

      &.--is-active {
        position: absolute;
        inset: var(--header-height) 0 auto;
        z-index: z-index(header);

        padding: rem(24);
      }
    }

    &__btns {
      margin-left: auto;
    }
  }

  @include max-wide(phonesLg) {
    .button.--line {
      display: none;
    }
  }

  @include min-wide(laptop) {
    &__btn-menu {
      display: none;
    }
  }
}
